import React from 'react';

function About() {
  return ( 
    <div>
        <br/>
        This is About section!!
    </div>
  )
}

export default About;