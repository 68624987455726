import React from 'react';

function Work() {
    return(
        <div>
            <br/>
            This is Work section!!
        </div>
    )
}

export default Work;