import React from 'react';

function Education() {
    return (
        <div>
            <br/>
            Education section
        </div>

    )
}

export default Education;