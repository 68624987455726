import React from 'react';
import Container from 'react-bootstrap/Container';

function Home() {
  return (
    <div>
      <Container className="home-image">
        {/* <br/> */}
      </Container >
    </div>
  )
}

export default Home;